// Layout:: Public layout component

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isLoggedIn } from '../redux/auth/authSelector';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { selectUserDetails } from '../redux/user/userSelector';
import Header from '../components/Header/Header';
// import '../pages/Onboarding/Style/onboarding.style.scss';
// import '../pages/Onboarding/Style/onboarding.media.scss';

function PublicLayout({ children }) {
  // document.body.style.backgroundColor = '#fbf5f0';
  return (
    <div className="wrapper onbording">
      <main>
        <section>
          <Header />
          <div className="ob-body">
            <div className={'form-wrapper'}>{children}</div>
          </div>
        </section>
      </main>
      {/* <ToastContainer /> */}
    </div>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.any,
};

PublicLayout.defaultProps = {
  // children: <p>Shopdot</p>,
  children: {}
};

export default PublicLayout;
