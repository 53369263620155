import axios from '../utils/axios';
import { toast } from 'react-toastify';
import * as API_END_POINT from '../constants/api';
import * as types from './actionTypes';
// import { setShippingLoading } from '../redux/Brand/Shipping/shippingSlice';
// import { onChangePassword } from '../redux/Brand/security/securitySlice';
import {
  setBrandCategory,
  setBrandProfileDetails,
  setBrandValues,
} from '../redux/Brand/Profile/brandProfileSlice';
import {
  setRetailerProfileSaveResult,
  setRetailerProfileSaving,
  setRetilerProfileCompleted,
  setRetilerProfileStore,
} from '../redux/Retailer/Profile/retailerProfileSlice';
import {

  setRetailerStoreSaving, setRetailerStore
} from '../redux/Retailer/Storefront/storeFrontSlice';
import {
  sendRetaileNewConnectionRequest,
  setRetailerBrandProductsList,
  setRetailerBrandProfile,
  setRetailerBrandTagsValue,
  setRetailerBrandValuesList,
  setRetailerNewConnectionRequestError,
  setRetailerNewConnectionRequestSuccess,
  setRetailerProducts,
} from '../redux/Retailer/Brand/Products/retailerBrandProductsSlice';
import {
  setBrandFilters,
  setRetailerProductDetails,
  setStoreBrandFilters,
} from '../redux/Brand/Retailer/retailerSlice';
import { showBottomNotification } from './generalActions';
import { resetRetailerOrderReportState, setRequiredActionOrderCount, setRetailerOrderChannels, setRetailerOrderReportDownloading, setRetailerOrderReportFailure, setRetailerOrderReportSuccess, setRetailerOrders, setRetailerPendingOrders } from '../redux/Retailer/Orders/RetailerOrderSlice';
import { SEGMENT_RETAOLER_PROFILE_COMPLETED } from '../helpers/segmentHelper';
import { toUtcFormat } from '../helpers/helper';


import { setRetailerChannels, setRetailerChannelProducts } from '../redux/Retailer/Channels/RetailerChannelsSlice';
import { setBankDetails } from '../redux/Brand/GettingPaid2/gettingPaidSlice';


export function getRetailerProfileAction(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_END_POINT.RETAILER_PROFILE}`);
      if (response && response.data && response.data.code == 200) {
        dispatch(
          setBrandProfileDetails({
            type: types.BRAND_PROFILE,
            data: response.data.data?.retailerData,
          })
        );


        if (response?.data?.data?.retailerData?.retailer_details?.store_name) {
          dispatch(
            setRetilerProfileCompleted({
              profile: response?.data?.data?.retailerData?.retailer_details?.id,
              is_subscription_active: response?.data?.data?.subscription_details?.is_subscription_active,
              subscription_details: response?.data?.data?.subscription_details,
              shop_detail: response?.data?.data?.retailerData?.shop_detail[0],
              is_onboarding_completed: response?.data?.data?.retailerData?.is_onboarding_completed,
              active_plan: response?.data?.data?.subscription_details,
              paid: response?.data?.data?.retailerData?.payment_detail?.external_account_id
              // active_plan:
            })
          );

        }
      } else {
      }
    } catch (err) { }
  };
}

export function getRetailerStoreAction() {

  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_END_POINT.RETAILER_STORE_DATA}`);
      if (response && response.data && response.data.code == 200) {
        if (response?.data?.data) {
          dispatch(
            setRetailerStore(response?.data?.data)
          );
        }
      } else {
      }
    } catch (err) { }
  };
}

export async function getRetailerProfile(id) {
  try {
    const response = await axios.get(`${API_END_POINT.RETAILER_PROFILE}`);
    if (response && response.data && response.data.code == 200) {
      return response?.data?.data?.retailerData?.retailer_details;
    } else {
    }
  } catch (err) { }
}
export function updateRetailerProfileAction(formData, navigate) {
  return async (dispatch) => {
    try {
      dispatch(setRetailerProfileSaving());
      const response = await axios.post(
        API_END_POINT.RETAILER_PROFILE,
        formData
      );
      if (
        response &&
        response.status &&
        (response.status == 201 || response.status == 200)
      ) {

        toast.success("Your changes have been saved.")

        // dispatch(showBottomNotification(response?.data?.message));
        dispatch(setRetailerProfileSaveResult(response.data.data));
        dispatch(getRetailerProfileAction(formData.user_id));
        let address = [];
        address.push(formData.address1);
        if (formData.address2) { address.push(formData.address2); }
        address.push(formData.country);
        address.push(formData.state);
        address.push(formData.city);
        address.push(formData.zip);
        analytics.track(SEGMENT_RETAOLER_PROFILE_COMPLETED, {
          company_email_address: formData?.company_email_address,
          company_phone_number: formData?.company_phone_number,
          retailer_category: formData?.retailer_category_label,
          date: toUtcFormat(new Date()),
          email: formData?.email,
          first_name: formData?.first_name,
          last_name: formData?.last_name,
          store_name: formData?.store_name,
          website: formData?.store_website,
          referal_url: formData?.referal_url,
          user_id: formData?.user_id,
          address: address.join(),
          facebook_url: formData.facebook_url ?? '',
          instagram_url: formData.instagram_url ?? '',
          tiktok_username: formData.tiktok_url ?? '',
          business_type: formData.business_type ?? '',
          product_categories: formData?.retailer_product_categories_label,
          sub_user_of: formData?.sub_user_of
        });

        return response?.data;
      } else {
        toast.error('Something went wrong', {
          autoClose: false,
        });
        return false;
      }
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { errors } = data || {};
      let errorMsg;
      if (typeof errors === 'object') {
        if (Array.isArray(errors) && errors.length > 0) {
          const error = errors[0];
          errorMsg = JSON.stringify(error);
        }
      } else {
        errorMsg = errors;
      }
      toast.error(errorMsg ? errorMsg : 'Something went wrong', {
        autoClose: false,
      });
      return false;

    }
  };
}
export function createRetailerStoreAction(formData, navigate) {
  return async (dispatch) => {
    try {
      // console.log('formData',formData);
      // console.log('setRetailerStoreSaving',setRetailerStoreSaving());
      dispatch(setRetailerStoreSaving());
      const response = await axios.post(
        API_END_POINT.RETAILER_PROFILE,
        formData
      );
      // if (
      //   response &&
      //   response.status &&
      //   (response.status == 201 || response.status == 200)
      // ) {
      //   toast.success("Your changes have been saved.");

      //   // dispatch(showBottomNotification(response?.data?.message));
      //   dispatch(setRetailerProfileSaveResult(response.data.data));
      //   dispatch(getRetailerProfileAction(formData.user_id));
      //   let address = [];
      //   address.push(formData.address1);
      //   if(formData.address2){address.push(formData.address2);}
      //   address.push(formData.country);
      //   address.push(formData.state);
      //   address.push(formData.city);
      //   address.push(formData.zip);
      //   analytics.track(SEGMENT_RETAOLER_PROFILE_COMPLETED, {
      //     company_email_address: formData?.company_email_address,
      //     company_phone_number: formData?.company_phone_number,
      //     retailer_category: formData?.retailer_category_label,
      //     date: toUtcFormat(new Date()),
      //     email: formData?.email,
      //     first_name: formData?.first_name,
      //     last_name: formData?.last_name,
      //     store_name: formData?.store_name,
      //     website: formData?.store_website,
      //     referal_url: formData?.referal_url,
      //     user_id: formData?.user_id,
      //     address: address.join()
      //   });

      //   return response?.data;
      // } else {
      //   toast.error('Something went wrong');
      //   return false;
      // }
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { errors } = data || {};
      let errorMsg;
      if (typeof errors === 'object') {
        if (Array.isArray(errors) && errors.length > 0) {
          const error = errors[0];
          errorMsg = JSON.stringify(error);
        }
      } else {
        errorMsg = errors;
      }
      toast.error(errorMsg ? errorMsg : 'Something went wrong', {
        autoClose: false,
      });
      return false;

    }
  };
}

export function updatePreferences(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(API_END_POINT.PREFERENCES, data);
      if (response.status === 200) {
        dispatch(showBottomNotification('Preferences Updated'));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export async function addBillingDetailsAction(data) {
  try {
    const response = await axios.post(API_END_POINT.RETAILER_BILLING, data);
    if (response.status === 200) {
      toast.success("Card added! Your payment info is updated.")
      return response;
    }
  } catch (err) {

    const { response } = err || {};
    const { data } = response || {};
    const { errors } = data || {};
    let errorMsg;
    if (typeof errors === 'object') {
      if (Array.isArray(errors) && errors.length > 0) {
        const error = errors[0];

        errorMsg = errors.map(
          (word, index) => word?.message
        ).join(', ')

        // errorMsg = JSON.stringify(error);
      }
    } else {
      errorMsg = errors;
    }
    toast.error(errorMsg ? errorMsg : 'Something went wrong', {
      autoClose: false,
    });
    return false;

    // return err.response;

  }
}

export async function getBillingAction() {
  try {
    const response = await axios.get(API_END_POINT.RETAILER_BILLING);
    if (response.status === 200) {
      return response;
    }
  } catch (err) {
    return err.response;
  }
}

export function getRetailerBrandProductsListAction(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_END_POINT.RETAILER_BRANDS}`,
        data
      );
      if (response && response.data && response.data.code == 200) {
        dispatch(setRetailerBrandProductsList(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function updateNotificationAlertAction(data) {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        API_END_POINT.RETAILER_NOTIFICATION_ALERT,
        data
      );
      if (
        (response && response.data && response.data.code == 201) ||
        (response && response.data && response.data.code == 200)
      ) {
        dispatch(showBottomNotification('Notification Alert Updated'));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getRetailerBrandValuesAction(queryString = '') {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.RETAILER_BRAND_VALUES}${queryString}`
      );
      if (
        (response && response.data && response.data.code == 201) ||
        (response && response.data && response.data.code == 200)
      ) {
        dispatch(setRetailerBrandValuesList(response.data.data));
      } else {
      }
    } catch (err) { }
  };
}

export function retailerNewConnectionRequestAction(data) {
  return async (dispatch) => {
    try {
      dispatch(sendRetaileNewConnectionRequest());
      const response = await axios.post(
        API_END_POINT.RETAILER_NEW_CONNECTION_REQUEST,
        data
      );
      if (
        (response && response.data && response.data.code == 201) ||
        (response && response.data && response.data.code == 200)
      ) {
        dispatch(setRetailerNewConnectionRequestSuccess());
        dispatch(getRetailerBrandProfileAction(data.invitee_id))
        // dispatch(showBottomNotification('New Connection request sent successfully'));
      }
    } catch (err) {
      dispatch(setRetailerNewConnectionRequestError());
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors[0].invitee_id
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getRetailerBrandProfileAction(id) {
  return async (dispatch) => {
    dispatch(setRetailerBrandProfile([]));
    try {

      const response = await axios.get(
        `${API_END_POINT.RETAILER_BRAND_PROFILE(id)}`
      );
      if (
        (response && response.data && response.data.code == 201) ||
        (response && response.data && response.data.code == 200)
      ) {
        dispatch(setRetailerBrandProfile(response.data.data));
      } else {
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getRetailerProductsAction(data) {
  // alert("hello");
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_END_POINT.RETAILER_PRODUCTS}`,
        data
      );
      if (response && response.data && response.data.code == 200) {
        dispatch(setRetailerProducts(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getRetailerStoresAction(data) {
  // alert("hello");
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_END_POINT.STORE_DATA_LIST}`,
        data
      );
      // console.log('123->response', response);
      if (response && response.data && response.data.code == 200) {
        dispatch(setRetailerProducts(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}
export function getRetailerProductDetailsAction(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.RETAILER_PRODUCT_DETAILS(id)}`
      );
      if (response && response.data && response.data.code == 200) {

        dispatch(setRetailerProductDetails(response?.data?.data
        ));

      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getBrandFiltersAction(data, purpose = "") {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.BRAND_FILTERS}${purpose}`, data);
      if (response && response.data && response.data.code == 200) {
        dispatch(setBrandFilters(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getStoreBrandFiltersAction() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_END_POINT.STORE_BRAND_FILTERS}`);
      if (response && response.data && response.data.code == 200) {
        dispatch(setStoreBrandFilters(response?.data?.data));
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function getRetailerBrandTagsValueAction(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.RETAILER_BRAND_TAGS_VALUE(id)}`
      );
      if (response && response.data && response.data.code == 200) {
        dispatch(setRetailerBrandTagsValue(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}
export async function setCardAsDefault(data) {
  try {
    const response = await axios.put(`${API_END_POINT.RETAILER_BILLING}`, data);
    if (response && response.data && response.data.code == 200) {
      return response.data;
    }
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}
export async function deleteRetailerCard(data) {
  try {
    const response = await axios.post(`${API_END_POINT.RETAILER_BILLING_DELETE}`, data);
    if (response && response.data && response.data.code == 200) {
      return response.data;
    }
  } catch (err) {
    toast.error(
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : 'Something went wrong', {
          autoClose: false,
        }
    );
  }
}

export function fetchRetailerOrders(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.RETAILER_ORDERS}`, data);
      if (response && response.data && response.data.code == 200) {
        if (response.data?.data.hasOwnProperty("count") && response.data?.data.hasOwnProperty("rows")) {
          dispatch(setRetailerOrders(response?.data?.data));
        } else {
          dispatch(setRetailerOrders({ count: 0, rows: [] }));
        }
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function fetchRetailerOrdersChannels() {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.RETAILER_ORDERS_CHANNEL}`, {});
      if (response && response.data && response.data.code == 200) {
        dispatch(setRetailerOrderChannels(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function downloadRetailerOrdersReportAction(data) {
  return async (dispatch) => {
    try {
      dispatch(setRetailerOrderReportDownloading())
      const response = await axios.post(
        `${API_END_POINT.DOWNLOAD_RETAILER_ORDERS_REPORT}`,
        data,
        { responseType: 'blob' }
      );
      if (response && response.data && response.status == 200) {
        window.open(URL.createObjectURL(response.data))
        dispatch(setRetailerOrderReportSuccess());
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
      dispatch(setRetailerOrderReportFailure())
    } finally {
      dispatch(resetRetailerOrderReportState())
    }
  };
}

export function downloadBrandOrdersReportAction(data) {
  return async (dispatch) => {
    try {
      // console.log('data~~~~~~~~~~',data);
      // dispatch(setRetailerOrderReportDownloading())
      const response = await axios.post(
        `${API_END_POINT.DOWNLOAD_BRAND_ORDERS_REPORT}`,
        data,
        { responseType: 'blob' }
      );
      // console.log('downloadBrandOrdersReportAction~~~~~~~~~~',response);
      if (response && response.data && response.status == 200) {
        window.open(URL.createObjectURL(response.data));
        // dispatch(setRetailerOrderReportSuccess());
      }
    } catch (err) {
      // console.log('downloadBrandOrdersReportAction~~~~~~~~~~', err.response);
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          // : 'Something went wrong'
          : 'No Order Details Found', {
            autoClose: false,
          }
      );
      // dispatch(setRetailerOrderReportFailure())
    } finally {
      // dispatch(resetRetailerOrderReportState())
    }
  };
}

export function connectShopifyAction(formData) {
  return async (dispatch) => {
    try {
      const params = {
        shop: formData.name,
        user_id: formData.user_id,
      };

      const response = await axios.post(
        `${API_END_POINT.SHOPIFY_CHECK_UPDATE}`,
        { shop: formData.name }
      );

      if (
        response &&
        response.data &&
        (response.data.code == 201 || response.data.code == 200)
      ) {
        fetch(
          `${API_END_POINT.PLATFORM}/shopify-integration?shop=${formData.name}&user_id=${formData.user_id}`,
          {
            redirect: 'manual',
          }
        )
          .then((res) => {
            console.log('res----', res);
            if (res.type === 'opaqueredirect') {
              console.log('res.url', res.url);
              window.location.href = res.url;
            } else {
              return res;
            }
          })
          .catch(() => { });
      } else if (response && response.code == 400) {
        toast.error(response?.errors, {
          autoClose: false,
        });
      } else {
        toast.error('Something went wrong', {
          autoClose: false,
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.location) {
        window.location = err.response.data.location;
      }
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}

export function disconnectShopifyAction(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_END_POINT.DISCONNECT_SHOPIFY,
        formData
      );
      if (response && response.data && response.data.code == 200) {
        dispatch(getRetailerProfileAction(formData.user_id));
      } else {
      }
    } catch (err) { }
  };
}

export function getBrandProfileAction(id) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_END_POINT.BRAND_PROFILE}/${id}/`);
      if (response && response.data && response.data.code == 200) {
        dispatch(
          setBrandProfileDetails({
            type: types.BRAND_PROFILE,
            data: response.data.data,
          })
        );

        dispatch(
          setProfileCompleted({
            preference: response?.data?.data?.brandPreference?.id,
            profile: response?.data?.data?.brand_profile?.store_name,
            paid: response?.data?.data?.payment_detail?.external_account_id,
            shipping: response?.data?.data?.shippingRate?.id,
            integration: response?.data?.data?.shop_detail?.is_active,
            integration_shop: response?.data?.data?.shop_detail?.shop,
            plan: response?.data?.data?.subscription_details?.planInfo?.id,
            active_plan: response?.data?.data?.subscription_details
          })
        );
        dispatch(
          setStatusIndicator({
            billing:
              response?.data?.data?.payment_detail?.merchant_status ===
              'ACTIVE',
            products: response?.data?.data?.active_product_count > 0,
            store: response?.data?.data?.shop_detail?.is_active,
            onboarding: response?.data?.data?.shippingRate?.id,
          })
        );
        dispatch(setBrandShopifyStore(response?.data?.data?.shop_detail));
      } else {
      }
    } catch (err) { }
  };
}

export function updateRetailerOnboarding(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_END_POINT.RETAILER_ONBOARDING_UPDATE,
        {
          "is_onboarding_completed": true
        }
      );
      if (response && response.data && response.data.code == 200) {
        dispatch(getRetailerProfileAction(formData.user_id));
      } else {
      }
    } catch (err) { }
  };
}



export function fetchRetailerPendingOrders(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.RETAILER_PENDING_ORDERS}`, data);
      if (response && response.data && response.data.code == 200) {
        if (response.data?.data.hasOwnProperty("count") && response.data?.data.hasOwnProperty("rows")) {
          dispatch(setRetailerPendingOrders(response?.data?.data));
        } else {
          dispatch(setRetailerPendingOrders({ count: 0, rows: [] }));
        }
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  }
}

export function getRetailerChannelsAction() {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        API_END_POINT.GET_RETAILER_CHANNELS
      );
      if (response && response.data && response.data.code == 200) {
        dispatch(setRetailerChannels(response.data.data));
      } else {
        dispatch(setRetailerChannels([]));

      }
    } catch (err) { }
  };
}


export function publishRetailerListing(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_END_POINT.PULBLISH_RETAILER_LISTING, formData
      );
      if (response && response.data && response.data.code == 200) {
        if (response.data.success == true) {
          toast.success("Product published successfully.")
          return response.data
        }
        else {
          return false
        }
      } else {
          return false
      }
    } catch (err) {
      return false
    }
  };
}



export function getRetailerPublishProductListing(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_END_POINT.RETAILER_PULBLISH_PRODUCT_LISTING, formData
      );
      if (response && response.data && response.data.code == 200) {
        let data = {
          count: response?.data?.data?.count,
          rows: []
        }


        let productData = response?.data?.data?.rows?.filter((item) => item?.product_retailer[0]?.publishedProducts[0]?.shopify_status == 'active')
        // data.rows = productData
        data.rows = response?.data?.data?.rows

        dispatch(setRetailerChannelProducts(data));
        // dispatch(getRetailerChannelsAction());
      } else {
        dispatch(setRetailerChannelProducts({ count: 0, rows: [] }));
        // dispatch(setRetailerChannels([]));

      }
    } catch (err) {
      dispatch(setRetailerChannelProducts({ count: 0, rows: [] }));
    }
  };
}


export function removePublishedProductFromShopify(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_END_POINT.RETAILER_REMOVE_PUBLISHED_PRODUCT, formData
      );
      if (response && response.data && response.data.code == 200) {

        if (response.data.success == true) {
          toast.success("Product removed successfully.")
          return response.data

        }
        else {
          return false
        }

        // dispatch(getRetailerChannelsAction());
      } else {
        // dispatch(setRetailerChannels([]));
        return false
      }
    } catch (err) {
      return false
    }

  };
}


export function syncPublishedProductFromShopify(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_END_POINT.RETAILER_SYNC_PUBLISHED_PRODUCT, formData
      );
      if (response && response.data && response.data.code == 200) {

        if (response.data.success == true) {
          toast.success("Product sync successfully.")
          return response.data

        }
        else {
          return false
        }

        // dispatch(getRetailerChannelsAction());
      } else {
        // dispatch(setRetailerChannels([]));
        return false
      }
    } catch (err) {
      return false
    }

  };
}


export function getBrandFiltersForPnedingOrdersAction(data, purpose = "") {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.BRAND_FILTERS_FOR_PENDING_ORDERS}${purpose}`, data);
      if (response && response.data && response.data.code == 200) {
        dispatch(setBrandFilters(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}


export function getBrandFiltersForShopifyChannelAction(data, purpose = "") {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.BRAND_FILTERS_FOR_SHOPIFY_CHANNEL}${purpose}`, data);
      if (response && response.data && response.data.code == 200) {
        dispatch(setBrandFilters(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}


export function getBrandFiltersForAllOrdersAction(data, purpose = "") {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${API_END_POINT.BRAND_FILTERS_FOR_ALL_ORDERS}${purpose}`, data);
      if (response && response.data && response.data.code == 200) {
        dispatch(setBrandFilters(response?.data?.data));
      }
    } catch (err) {
      toast.error(
        err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went wrong', {
            autoClose: false,
          }
      );
    }
  };
}
export function getRequiredOrderActions() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_END_POINT.RETAILER_ORDER_REQUIRED_ACTION}`);
      if (response && response.data && response.data.code == 200) {
        dispatch(setRequiredActionOrderCount(response.data.data.count))
      } else {
      }
    } catch (err) { }
  };
}
export function readRequiredOrderActions(data) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${API_END_POINT.RETAILER_ORDER_REQUIRED_ACTION}`, data);
      if (response && response.data && response.data.code == 200) {
        dispatch(getRequiredOrderActions())
      }
    } catch (err) { }
  };
}
export async function retailerAsCustomerAction(formData, bankFormData, myIp = "") {
  try {
    const response = await axios.post(
      API_END_POINT.RETAILER_AS_CUSTOMER,
      { ...formData, ...bankFormData }, {
      headers: {
        "X-Real-IP": myIp
      }
    }
    );
    if (response && response.data && response.data.code == 201) {
      return {
        isSuccess: true,
        data: response.data.data,
      };
    } else {
      let er = "Something went wrong"
      toast.error(<>{er}</>, {
        autoClose: false,
      });
      return {};
    }
  } catch (err) {
    let er = "Something went wrong"
    let errorMsg = [];
    if (err.response?.data?.errors?.errors) {
      const resErrors = err.response?.data?.errors?.errors;
      if (Array.isArray(resErrors)) {
        console.log(resErrors);
        if (resErrors) {
          resErrors.map((r) => {
            errorMsg.push(<li>{r?.field} {r?.message}</li>);
          })
        }
      }
    } else {
      er = err.response?.data?.errors?.errorMessage
    }
    toast.error(er, {
      autoClose: false,
    });
  }
}
export function getBankDetailsAction(customerId, externalAccountId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_END_POINT.PAYMENT_CUSTOMER}/${customerId}/external-account/${externalAccountId}`
      );
      if (response && response.data && response.data.code == 201) {
        dispatch(setBankDetails(response.data.data));
      } else {
        toast.error('Something went wrong', {
          autoClose: false,
        });
      }
    } catch (err) {
      const error = err?.response?.data?.errors;
      if (error.startsWith(`customer with id`)) {
      } else {
        toast.error(
          err && err.response && err.response.data && err.response.data.errors
            ? err.response.data.errors
            : 'Something went wrong', {
              autoClose: false,
            }
        );
      }
    }
  };
}