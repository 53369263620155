// Component:: Header component

import React from 'react';
import PropTypes from 'prop-types';
import logoPng from '../../assets/images/logos/new-logo.png';
// import '../../pages/Onboarding/Style/onboarding.style.scss'
// import '../../pages/Onboarding/Style/onboarding.media.scss'

function Header({ pageTitle }) {
  return (
      <div className="ob-head">
        <img src={logoPng} alt="logo" className="logo-round" />
        {/* <h1>{pageTitle}</h1> */}
      </div>
    
  );
}

// Header.propTypes = {
//   pageTitle: PropTypes.string,
// };

// Header.defaultProps = {
//   pageTitle: 'Signin',
// };

export default Header;
