/* eslint-disable no-unused-vars */

// Auth flow:: Signin page

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PublicLayout from '../../../layout/PublicLayout';
import {
  loginAction,
  sendVerifyEmailAction,
} from '../../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAuthReducer,
  resetLoginStateRequest,
  setRedirectAfterLogin,
} from '../../../redux/auth/authSlice';
import {
  loginSuccess,
  selectLoginError,
  selectRedirectAfterLogin,
} from '../../../redux/auth/authSelector';
import { selectUserDetails } from '../../../redux/user/userSelector';
import { createBrowserHistory } from 'history';
import '../../Onboarding/Style/onboarding.style.scss';
// import '../../Onboarding/Style/onboarding.media.scss';
import {
  getBillingAction,
  getRetailerProfile,
} from '../../../actions/retailerActions';
import { setRetilerProfileCompleted } from '../../../redux/Retailer/Profile/retailerProfileSlice';
import { ALLOWED_PLATFORMS } from '../../../constants';
// Validation schema of form field
const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('Enter email')
      .min(5)
      .max(254)
      .email(
        'Email address must be in correct format.',
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      ),
    password: yup.string().required('Enter Password'),
  })
  .required();

function Profile() {
  const [passwordType, setPasswordType] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const success = useSelector(loginSuccess);
  const userDetails = useSelector(selectUserDetails);
  const loginError = useSelector(selectLoginError);
  const history = createBrowserHistory();
  const redirectAfterLogin = useSelector(selectRedirectAfterLogin);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) });

  useEffect(() => {
    const subscription = watch((value) => {
      for (const key in value) {
        if (!value[key]) {
          setDisabled(true);
          return;
        }
        setDisabled(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const redirectRetailer = () => {
    getRetailerProfile(userDetails.id).then((profileRes) => {
      if (userDetails?.is_onboarding_completed) {
        navigate('/retailer/dashboard', { replace: true });
        return;
      }

      navigate('/retailer/setting', { replace: true });
      return;

      // getBillingAction().then((billingRes)=>{
      //   if(Object.keys(billingRes).length > 0 && billingRes?.data?.data && billingRes?.data?.data.length > 0 && profileRes?.company_name){
      //     dispatch(
      //       setRetilerProfileCompleted({
      //         profile: profileRes?.id,
      //       })
      //     );
      //     dispatch(
      //       setRetilerProfileCompleted({
      //         paid: true,
      //       })

      //     );

      //     if(userDetails?.is_onboarding_completed)
      //     {
      //       navigate('/retailer/dashboard');
      //       return;
      //     }
      //     else{
      //       navigate('/retailer/dashboard');
      //       return;
      //     }

      //     // console.log('userDetails',userDetails);

      //   }else{

      //     navigate('/retailer/setting/');
      //     return;
      //   }
      // })
    });
  };

  const redirectBrandd = () => {
    if (userDetails?.UserPlatform) {
      if (
        !ALLOWED_PLATFORMS.includes(
          userDetails?.UserPlatform?.platform?.name.toLowerCase()
        )
      ) {
        navigate(
          `/personalized-not-supported/${userDetails?.UserPlatform?.platform?.name}`,
          { replace: true }
        );
        return;
      }
    } else if (userDetails?.is_subscription_active) {
      navigate('/dashboard', { replace: true });
      return;
    } else if (
      Object.keys(userDetails?.sub_user_of).length > 0 &&
      userDetails?.sub_user_of[0]?.parentUser?.is_subscription_active
    ) {
      navigate('/dashboard', { replace: true });
      return;
    } else {
      navigate('/brand/setting', { replace: true });
      return;
    }
  };
  useEffect(() => {
    if (success) {
      //checking is subuser
      if (
        userDetails?.sub_user_of &&
        Object.keys(userDetails?.sub_user_of).length > 0
      ) {
        if (userDetails?.role && userDetails?.role?.name) {
          console.log(userDetails?.role?.name);
          if (userDetails?.role?.name === 'retailer') {
            dispatch(
              setRetilerProfileCompleted({
                profile: true,
              })
            );
            dispatch(
              setRetilerProfileCompleted({
                paid: true,
              })
            );
            navigate('/retailer/dashboard', { replace: true });
            return;
          }
          //  return false;
          if (userDetails?.is_subscription_active) {
            navigate('/dashboard', { replace: true });
            return;
          } else if (
            Object.keys(userDetails?.sub_user_of).length > 0 &&
            userDetails?.sub_user_of[0]?.parentUser?.is_subscription_active
          ) {
            navigate('/dashboard', { replace: true });
            return;
          }

          navigate('/brand/setting', { replace: true });

          return;
        }
        navigate('/personalize', { replace: true });
      }
      if (userDetails?.is_email_verified) {
        if (userDetails?.role && userDetails?.role?.name) {
          if (userDetails?.role?.name === 'retailer') {
            redirectRetailer();
            return;
          }
          redirectBrandd();
          return;
        }
        navigate('/personalize', { replace: true });
      } else {
        navigate('/verify-email', { replace: true });
        // if (userDetails?.role && userDetails?.role?.name) {
        //   if (userDetails?.role?.name === 'retailer') {
        //     navigate('/retailer-onboarding');
        //   } else {
        //     if(userDetails?.is_onboarding_completed){
        //       navigate("/dashboard")
        //     }else{
        //       navigate('/brand-onboarding');
        //     }
        //   }
        // } else {

        //   // navigate('/email-verification-success');
        // }
        // navigate('/personalize');
        // navigate('/verify-email');
      }
      dispatch(clearAuthReducer());
    }
  }, [success]);

  const onSubmit = (data) => {
    if (loginError) {
      dispatch(resetLoginStateRequest());
    }
    data.email = String(data.email.toLowerCase()).trim();
    const device_id = localStorage.getItem('device_id');
    dispatch(loginAction({ ...data, device_id: device_id }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      {/* <Header pageTitle="Sign In" /> */}
      <PublicLayout>
        {/* <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form-area">
            <h1 className="mb-4">Log in</h1>
            <div className="form-group">
              {loginError ? (
                <div
                    className="alert alert-error alert-height mb-4"
                    role="alert"
                >
                  {loginError}
                </div>
              ) : null}
              <div className="category-form-input">
                <div className="form-input">
                  <input
                      className={`${errors?.email ? 'is-invalid' : ''
                      } form-control`}
                      type="text"
                      name="email"
                      required=""
                      {...register('email', {
                      required: true,
                    })}
                      placeholder="Email address"
                  />
                  {errors.email && (
                    <span className="invalid-feedback mb-0">
                      {errors.email?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="category-form-input mb-5">
                <div className="form-input">
                  <input
                      className={`${errors?.password ? 'is-invalid ' : ''
                      } form-control`}
                      type={passwordType ? 'password' : 'text'}
                      name="password"
                    // required='true'

                    maxLength={72}
                    placeholder="Enter password"
                    {...register('password', {

                      required: true,
                    })}
                      onKeyPress={handleKeyPress}
                  />
                  <span
                      className={`password-icon-top password-show ${passwordType ? '' : 'active'
                      }`}
                      onClick={() => setPasswordType(!passwordType)}
                  />
                  {errors.password && (
                    <span className="invalid-feedback mb-0">
                      {errors.password?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="link-color form-input text-left">
                <Link to="/forgot-password">Forgot Password</Link>
              </div>
              <div className="form-input mt-5">
                <button
                    disabled={disabled}
                    type="submit"
                    className="button w-100 large"
                >
                  Log in
                </button>
              </div>
              <div className="form-input mt-4">
                <small className="fs-13">
                  Don&apos;t have a ShopDot account? Create an account
                  <a
                      href="javascript: void(0)"
                      target="_blank"
                      onClick={(e) => {
                          e.preventDefault();
                          window.open('/signup', '_blank');
                        }}
                  >
                    {' '}
                    here.
                  </a>
                </small>
              </div>
            </div>
          </div>
        </form> */}
        <div className="auth-sign-in-form mx-auto">
          <profile-component
            tenantid="ywAjgBu3W1L1WNjccdptszRqCnp2BFkdtRvevptFl/e6LGZIfOZ7jc90JzFIFsW4"
            documentId=""
          ></profile-component>
        </div>
      </PublicLayout>
    </>
  );
}

export default Profile;
